import React, {useRef, useState} from 'react';
import {Text, Group, Button, rem, useMantineTheme, Modal, Loader} from '@mantine/core';
import {Dropzone, MIME_TYPES} from '@mantine/dropzone';
import {IconCloudUpload, IconX, IconDownload} from '@tabler/icons-react';
import classes from './VideoFileUploaderModal.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {videoListActions} from "src/components/Library/store";
import {Typography} from "antd";
import MuxUploader, {MuxUploaderFileSelect} from "@mux/mux-uploader-react";
import axios from "axios";
import {postCreateVideoFromFile} from "../../../api/video/postCreateVideoFromFile";
import {getVideoFileDuration} from "../../../shared/helpers/getVideoFileDuration";
import {maxVideoDurationInSeconds} from "../../../shared/consts";
import {UpChunk} from "@mux/upchunk";
import {notifications} from "@mantine/notifications";
import {useNavigate} from "react-router-dom";
import {invalidateQueryVideos} from "../../../shared/query/invalidateQueryVideos";
import {QueryVideoFilter} from "../../../api/video/getVideos";
import {postVideoWasUploaded} from "../../../api/video/postVideoWasUploaded";
import {useVideoFilesUploader} from "../../../shared/useVideoFilesUploader";

interface VideoFileUploadProps {
  isOpen: boolean;
  close: () => void;
}

export const VideoFileUploaderModal: React.FC<VideoFileUploadProps> = ({isOpen, close}) => {
  const theme = useMantineTheme();
  const openRef = useRef<() => void>(null);
  const navigation = useNavigate();
  const { videos, setVideos, upload} = useVideoFilesUploader()

  const actionHandler = async () => {
    if (videos.length > 0) {
      upload().then(()=>{
        close();
        navigation(`/library/source?subFilters=Upload`)
      })
    } else {
      openRef.current?.();
    }
  }
  const isSelected = videos.length > 0;


  return (
    <Modal id="video-uploader" opened={isOpen} onClose={close} classNames={{
      content: classes.modalContent,
      inner: classes.modalInner,
      body: classes.modalBody,
      header: classes.modalHeader,
    }} centered>
      <div className={classes.wrapper}>
        <Dropzone
          openRef={openRef}
          onDrop={(files) => {
            setVideos(files);
          }}
          className={classes.dropzone}
          radius="md"
          accept={[MIME_TYPES.mp4, 'video/avi', 'video/quicktime']}
          maxSize={300 * 1024 ** 2}
        >
          <div style={{pointerEvents: 'none'}}>
            <Group sx={{
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <Dropzone.Accept>
                <IconDownload
                  style={{width: rem(50), height: rem(50)}}
                  color={theme.colors.blue[6]}
                  stroke={1.5}
                />
              </Dropzone.Accept>
              <Dropzone.Reject>
                <IconX
                  style={{width: rem(50), height: rem(50)}}
                  color={theme.colors.red[6]}
                  stroke={1.5}
                />
              </Dropzone.Reject>
              <Dropzone.Idle>
                <IconCloudUpload style={{width: rem(133), height: rem(133), marginTop: isSelected ? '4px' : '63px'}}
                                 stroke={0.5}/>
              </Dropzone.Idle>
            </Group>

            <Text ta="center" fw={700} fz="lg" mt="xl">
              <Dropzone.Accept><Typography>
                Drop files here
              </Typography></Dropzone.Accept>
            </Text>
            <Text className={classes.uploadVideos}>
              <Dropzone.Idle>Upload videos</Dropzone.Idle>
            </Text>
            {isSelected ?
              videos.map((file, index) => {
                const number = index + 1;
                return (
                  <Text key={file.name}>
                    {number}: {file.name}
                  </Text>
                )
              })
              : <Text className={classes.subText}>
                Drag&apos;n&apos;drop files here to upload. We can accept only <i>.mpg</i> <i>.avi</i>
                <i>.mov</i> files <br/>
                that are less than 300mb in size.
              </Text>
            }
          </div>
        </Dropzone>

        <Button className={classes.control} size="md" radius="xl" onClick={actionHandler}>
          {isSelected ? 'Upload' : 'Select files'}
        </Button>
      </div>
    </Modal>
  );
};

