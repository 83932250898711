import { Box, createStyles } from '@mantine/core';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Tabs } from 'src/components/Tabs/Tabs';
import { selectActiveWidget } from '../../selectors';
import { SetupTabs } from '../../types';
import { Installation } from './Installation';
import { Settings } from './Settings';
import { Targeting } from './Targeting';
import { Videos } from './Videos';

type TabItem = {
  value: SetupTabs;
  label: string;
};
const data: TabItem[] = [
  {
    value: SetupTabs.videos,
    label: 'Videos',
  },
  {
    value: SetupTabs.settings,
    label: 'Settings',
  },
  {
    value: SetupTabs.targeting,
    label: 'Targeting',
  },
  {
    value: SetupTabs.installation,
    label: 'Installation',
  },
];

const useStyles = createStyles(() => ({
  stepsContainer: {
    display: 'flex',
    height: '100%',
  },
  leftContentWrapper: {
    width: '541px',
    padding: '28px 48px',
    position: 'relative',
  },
  rightContentWrapper: {
    display: 'flex',
    flexGrow: 1,
    position: 'relative',
  },
  scrollableArea: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    overflow: 'auto',
  },
}));
interface SetupStepProps {
  currentTab: SetupTabs;
  changeTab: (tab: SetupTabs) => void;
}
export const SetupStep: FC<SetupStepProps> = ({ currentTab, changeTab }) => {
  const { classes } = useStyles();
  const activeWidget = useSelector(selectActiveWidget);

  const tabs = useMemo(
    () => (activeWidget?.page === 'home' ? data.filter((tab) => tab.value !== 'targeting') : data),
    [activeWidget?.page]
  );

  return (
    <Box className={classes.stepsContainer}>
      <Box className={classes.leftContentWrapper}>
        <Tabs currentTab={currentTab} onChange={changeTab} data={tabs}>
          <>
            {currentTab === 'videos' && <Videos />}
            {currentTab === 'settings' && <Settings />}
            {currentTab === 'targeting' && <Targeting />}
            {currentTab === 'installation' && <Installation />}
          </>
        </Tabs>
      </Box>
      <Box className={classes.rightContentWrapper}>
        <Box className={classes.scrollableArea} id="tabs-portal" />
      </Box>
    </Box>
  );
};
