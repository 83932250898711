import { AxiosRequestConfig } from 'axios';
import { apiClient } from '../../client';

interface Config extends AxiosRequestConfig {
  videoId: string;
}

export type  GetTaggedProductsWithVideoResponse = {
  id: string;
  product_id: string;
  product_type: string | null;
  image: string | null;
  title: string;
  myshopify_domain: string | null;
  price?: number;
  collectionIds: string[];
  isTagged: boolean;
}[]

export const getTaggedProductsWithVideo = ({ videoId, ...config }: Config) =>
  apiClient.get<GetTaggedProductsWithVideoResponse>(`/bff/video/platform/${videoId}/tagged-products`, config);
