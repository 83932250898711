import { ActionIcon, Box, createStyles, ScrollArea, Text, TextInput } from '@mantine/core';
import {FC, useCallback, useMemo} from 'react';
import { TargetingPage } from './types';
import styles from './Targeting.module.scss';
import { SelectDropdownButtons, SelectItem } from './TargetingSelect.components';
import { useTargetingSelect } from 'src/shared/hooks/useTargetingSelect';
import { ReactComponent as SelectIcon } from 'src/shared/icons/select-icon.svg';
import { IconX } from '@tabler/icons-react';
import {
  SelectWithCheckboxes
} from "../../../../../shared/components/SearchableSelectWithCheckboxes/SelectWithCheckboxes";
import {SelectedData} from "../../../../../shared/components/SearchableSelectWithCheckboxes/interface";

const useStyles = createStyles((theme) => ({
  dropdown__scrollableArea: {
    padding: '5px',
    boxSizing: 'border-box',
  },
  notFound: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '67px',
    padding: '4px 10px',
    color: theme.colors.greyLight[2],
  },
  inputRightSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '72px',
  },
  input: {
    paddingRight: '72px',
  },

  select__iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    marginRight: '14px',
  },
  select: {
    position: 'relative',
  },
  dropdown: {
    position: 'absolute',
    top: '44px',
    borderRadius: '4px',
    border: `1px solid ${theme.colors.greyDark[1]}`,
    backgroundColor: theme.colors.greyDark[5],
    zIndex: 300,
  },
}));
const getSelectLabel = (page: TargetingPage) => {
  return page === 'collection' ? 'Collection' : 'Product';
};
interface TargetingSelectProps {
  page: TargetingPage;
}

export const TargetingSelect: FC<TargetingSelectProps> = ({ page }) => {
  const { classes } = useStyles();
  const {
    onChange,
    onCancel,
    onConfirm,
    disableConfirmButton,
    selectData,
    selectValue,
    dropdownState,
    dropdownHandler,
    searchHandler,
    search,
  } = useTargetingSelect();
  const onChangeHandler = useCallback((value: { value: SelectedData, checked: boolean }) => {
    onChange(value.value);
  }, [onChange])

  const selectedDataMapped = useMemo(()=>{
    const selectedValueSet = new Set(selectValue);
    return selectData.map((item) => {
      return {
        ...item,
        checked: selectedValueSet.has(item.value)
      }
    })
  },[selectData, selectValue])

  return (
    <Box className={styles.select__container}>
      <Text color="greyLight.2">{getSelectLabel(page)} page</Text>
      <Box className={classes.select}>
       <Box w={320}>
         <SelectWithCheckboxes
           placeholder="Select page"
           selectData={selectedDataMapped}
           onChange={onChangeHandler}
           search={search}
           onSearch={searchHandler}
           withCheckboxes={true}
           dropdownBottom={ <SelectDropdownButtons
             onConfirm={onConfirm}
             onCancel={onCancel}
             disableConfirmButton={disableConfirmButton}
           />}
         />
       </Box>
      </Box>
    </Box>
  );
};
