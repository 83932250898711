import styles from './VideoCard.module.scss';
import { FC } from 'react';
import classNames from 'classnames';
import {ActionIcon, Button, Tooltip} from '@mantine/core';
import { Checkbox } from '@mantine/core';
import { VCContentProps, VCThumbnailContainerProps } from './VideoCard.types';
import { ReactComponent as IconExclamationCircle } from '../../shared/icons/exclamationCircle.svg';
import { Loader } from '@mantine/core';
import { CardMenuDropdown } from '../CardMenuDropdown/CardMenuDropdown';
import { BrandInstagram, ShoppingBag, Trash } from 'tabler-icons-react';
import { useOptimizedThumbnail } from "../../shared/hooks/useOptimizedThumbnail";

export const VCContent: FC<VCContentProps> = ({
  title,
  onAddProducts = () => { },
  isDataLoaded,
  readOnly,
}) => {
  const btnClass = classNames(styles.content__products, {
    [`${styles.content__productsDefault}`]: !isDataLoaded,
  });

  const contentClasses = classNames(styles.content, {
    [styles.contentHeight__readOnly]: readOnly,
  });
  return (
    <div className={contentClasses}>
      <Tooltip label={title}>
        <h3 className={styles.content__title}>{title}</h3>
      </Tooltip>
      {!readOnly && (
        <Button className={btnClass} onClick={onAddProducts} disabled={!isDataLoaded}>
          Tag products
        </Button>
      )}
    </div>
  );
};

export const VCLoader = () => {
  return (
    <div className={styles.loader}>
      <Loader
        color="green.0"
        size="xl"
      />
    </div>
  );
};

export const VCThumbnailContainer: FC<VCThumbnailContainerProps> = ({
  id,
  type,
  captionUrl,
  checked,
  onChecked = () => { },
  onDelete = () => { },
  onRename = () => { },
  isShoppable,
  readOnly,
  hasDeleteButton,
  duration,
  gif,
}) => {
  const blockClasses = classNames(styles.block, {
    [styles.block__readOnly]: readOnly,
    [styles.block__hasDeleteButton]: hasDeleteButton,
  });
  const upblockClasses = classNames(styles.upblock, {
    [styles.upblock__readOnly]: readOnly,
  });
  const imgClasses = classNames(styles.cardImage, {
    [styles.thumbnailHeight__readOnly]: readOnly,
  });
  const thumbnailClasses = classNames(styles.imgContainer, {
    [styles.thumbnailHeight__readOnly]: readOnly,
    [styles.gif]: gif,
  });

  const thumbnailUrl = useOptimizedThumbnail(gif, 250)

  const gifUrl = thumbnailUrl?.gifUrl ?? gif;
  return (
    <>
      <div className={thumbnailClasses}>
        <img src={captionUrl} alt={`Thumbnail of ${id} video`} className={imgClasses} />
        <picture>
          <source srcSet={thumbnailUrl?.webpUrl} className={imgClasses} type="image/webp" />
          {gifUrl && <img src={gifUrl} className={imgClasses} />}
        </picture>
      </div>
      <div className={blockClasses}>
        {hasDeleteButton && (
          <div className={upblockClasses}>
            <ActionIcon variant="transparent" onClick={() => onDelete(id)}>
              <Trash strokeWidth={1} color="white" />
            </ActionIcon>
          </div>
        )}
        {!readOnly && (
          <div className={upblockClasses}>
            <div>
              <Checkbox
                color="teal"
                className={styles.checkbox}
                checked={checked}
                onChange={() => onChecked(id)}
                size={16}
              />
            </div>
            <CardMenuDropdown
              itemId={id}
              onRename={() => onRename(id)}
              onDelete={() => onDelete(id)}
            />
          </div>
        )}
        <div className={styles.downblock}>
          <div className={styles.downblock__icons}>
            {isShoppable && <ShoppingBag color="white" strokeWidth={1} />}
            {type === 'Instagram' && <BrandInstagram color="white" strokeWidth={1} size={24} />}
          </div>
          <div className={styles.downblock__time}>{duration}</div>
        </div>
      </div>
    </>
  );
};

export const VCError = () => {
  return (
    <div className={styles.error}>
      <div className={styles.error__body}>
        <IconExclamationCircle className={styles.error__icon} />
        <span className={styles.error__text}>Error loading video file</span>
      </div>
    </div>
  );
};
