import {FC, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {
  Avatar,
  Box,
  Group,
  Text,
  ScrollArea, ActionIcon, createStyles,
} from '@mantine/core';
import {
  SelectWithCheckboxes
} from "../../shared/components/SearchableSelectWithCheckboxes/SelectWithCheckboxes";
import {SelectData} from "../../shared/components/SearchableSelectWithCheckboxes/interface";
import {Search} from "../../shared/components/Search/Search";
import {useDisclosure, useFocusTrap} from "@mantine/hooks";
import {ReactComponent as IconDelete} from 'src/shared/icons/delete2.svg';
import {useVirtualizer} from "@tanstack/react-virtual";



interface SelectedProduct {
  id: string;
  product_id: string;
  product_type: string | null;
  image: string | null;
  title: string;
  myshopify_domain: string | null;
  isTagged: boolean;
}

interface ItemComponentProps {
  data: {
    value: string;
    label: string;
    description?: string;
    image?: string;
  };
  onDelete: (id: string) => void;
}

const useItemComponentStyles = createStyles((theme) => ({
  icon: {
    width: '26px',
    height: '26px',
    path: {
      stroke: theme.colors.greyLight[2],
    },
  },
}))

const ItemComponent: React.FC<ItemComponentProps> = ({
                                                       data,
                                                       onDelete,
                                                     }) => {
  const {classes} = useItemComponentStyles();
  const handleDelete = () => {
    onDelete(data.value);
  }
  return (
    <Group noWrap>
      <Avatar src={data.image} radius="sm" size="lg"/>
      <div style={{flex: 1}}>
        <Text size="sm" weight={500}>
          {data.label}
        </Text>
        <Text size="xs" color="dimmed" weight={400}>
          {data.description}
        </Text>
      </div>
      <ActionIcon
        onClick={handleDelete}
        sx={{
          stroke: 'greyLight.2',
        }}>
        <IconDelete className={classes.icon}/>
      </ActionIcon>

    </Group>
  )
};

interface Props {
  onChange: (v: {
    selectedProducts: string[];
    nonSelectedProducts: string[];
  }) => void;
  selectedProducts?: SelectedProduct[];
  allProducts: SelectedProduct[];
}

const useStyles = createStyles((theme) => ({

  select__iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    marginRight: '14px',
  },
  select: {
    position: 'relative',
  },
  dropdown: {
    position: 'absolute',
    width: '100%',
    top: '4px',
    borderRadius: '4px',
    border: `1px solid ${theme.colors.greyDark[1]}`,
    backgroundColor: theme.colors.greyDark[5],
    zIndex: 300,
  },
}));
export const ProductSelectList: FC<Props> = ({onChange, allProducts, selectedProducts}) => {
  const {classes} = useStyles();
  const [search, setSearch] = useState<string>('');

  const mappedProducts: SelectData[] = useMemo(() => {
    return allProducts.map((product) => {
      return {
        label: product.title,
        value: product.id,
        image: product.image,
        title: product.title,
        checked: product.isTagged,
      }
    }).filter((item) => item.label.toLowerCase().includes(search.toLowerCase()));
  }, [allProducts, search]);

  const containerRef = useRef<HTMLDivElement>(null);

  const dropdownHeight = (containerRef.current?.offsetHeight ?? 250);
  const [isRendered, setIsRendered] = useState(false);
  useLayoutEffect(() => {
    setIsRendered(true);
  }, []);


  const onSelect = useCallback(({checked, value}: { value: string, checked: boolean }) => {
    if(checked) {
      onChange({
        selectedProducts: [value],
        nonSelectedProducts: [],
      });
    } else {
      onChange({
        selectedProducts: [],
        nonSelectedProducts: [value],
      });
    }
  }, [onChange])

  const onDelete = useCallback((id: string) => {
    onChange({
      selectedProducts: [],
      nonSelectedProducts: [id],
    });
  }, [onChange]);



  const focusTrapRef = useFocusTrap()



  const reviewTaggedProductsScrollAreaRef = useRef<HTMLDivElement>(null);
  const rowVirtualizer = useVirtualizer({
    count: selectedProducts?.length || 0,
    getScrollElement: () => reviewTaggedProductsScrollAreaRef?.current,
    estimateSize: () => 67,
    overscan: 4,
  });


  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}>
      <Box
        ref={focusTrapRef}
      >
        <SelectWithCheckboxes
          selectData={mappedProducts}
          onChange={onSelect}
          search={search}
          onSearch={setSearch}
          dropdownHeight={dropdownHeight}
        />
      </Box>
      <Box
        ref={containerRef}
        sx={{
          height: '100%',
          flex: 1,
          position: 'relative',
        }}>
        {isRendered &&
          <>


              <ScrollArea.Autosize
                sx={{
                  paddingTop: '27px',
                }}
                viewportRef={reviewTaggedProductsScrollAreaRef}
                mah={dropdownHeight}
                placeholder={''}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              >
                {selectedProducts && <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                  padding: '0 5px',
                  width: '100%',
                  position: 'relative',
                  height: rowVirtualizer.getTotalSize(),
                }}>
                  {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                    const product = selectedProducts[virtualRow.index];
                    const image = product?.image ? `${product.image}&width=126` : undefined;
                    return (
                      <div
                        key={virtualRow.key}
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: `${virtualRow.size}px`,
                          transform: `translateY(${virtualRow.start}px)`,
                        }}>
                        <ItemComponent
                          data={{
                            value: product.id,
                            label: product?.title,
                            image: image,
                          }}
                          onDelete={onDelete}
                        />
                      </div>
                    );
                  })}
                </Box>}
              </ScrollArea.Autosize>
          </>
        }
      </Box>

    </Box>

  )
};
