import React, {useState} from 'react';
import {Box, Center, Flex} from "@mantine/core";

import {MetaInformation, ShoppingCard} from "@chatpoint/widget-lib";

import {Widget as WidgetType, Widget as WidgetTypeFromLib} from "@chatpoint/widget-lib/dist/types/widget";
import {useSelector} from "react-redux";
import {selectActiveWidget} from "../../../../../selectors";

import {ShopifyProduct} from "@chatpoint/widget-lib/dist/types";
import {SegmentSwitch} from "../../../../../../SegmentSwitch/SegmentSwitch";
import {IconDeviceLaptop, IconDeviceMobile} from "@tabler/icons-react";
import classes from './ProductDetailsPreview.module.scss'
import {MobileView} from "../Layout/MobileView";
import {ViewManager} from "../Layout/Layout.components";



export const ProductDetailsPreview = () => {
  const widget = useSelector(selectActiveWidget);
  const productHandle = 'productHandle'
  const actions = { //TODO mb implement normal actions mock
    addDirectToCart: () => {
    },
    addDirectToCheckout: () => {
    },
    addToCart: () => {
    },
    fetchProduct: () => {
      return {
        images: [],
      } as unknown as ShopifyProduct
    },
  }


  const consumerWidget: WidgetType | undefined = widget ?  {
    ...widget,
    videoCount: 0,
  } : undefined

  const consumerWidgetProductDetails = widget?.productDetails?.filter((item) => item.name && item.isVisible).map((item) => ({
    name: item.name,
    value: item.value,
    metaInformation: item.metaInformation as MetaInformation
  })) || []

  return (
    <Box id="product-detials" className={classes.rootView} >
        <ViewManager mobile={
          <Flex sx={{flexGrow: 1}} justify="center">
            <MobileView isShowSkeleton={false}>
              {consumerWidget && <Box sx={{
                marginTop: '10px',
              }}>
                <ShoppingCard
                  widgetId={widget?.id ?? 'pseudoId'}
                  widget={consumerWidget}
                  dataRetrieval={{
                    fetchProduct: actions.fetchProduct,
                  }}
                  cart={undefined}
                  handle={productHandle}
                  actions={actions}
                  platformMode="product-details-mobile"
                  productDetails={consumerWidgetProductDetails}
                />
              </Box>}
            </MobileView>
          </Flex>
        }
        />

    </Box>
  );
};

