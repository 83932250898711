import {useMutation} from "@tanstack/react-query";
import {patchWidgetsBulk} from "../../../api/widget/patchWidgetsBulk";
import {Widget} from "../../../components/Widget/types";
import {useGetWidgetsManger} from "./useGetWidgetsManger";

export const usePatchBulkWidgets = () => {
  const {refetch} = useGetWidgetsManger()
  return useMutation({
    mutationFn: async (data: { ids: string[], data: Partial<Widget>}) => {
     await patchWidgetsBulk(data)
    },
    onSuccess: () => {
      refetch()
    }
  })
}
