import { Box, createStyles, Portal, Text } from '@mantine/core';
import { VideoCard } from '../../../../VideoCard/VideoCard';
import { VideoResponseDeprecated } from '../../../../Library/LibraryHeader/types';
import { FC, useState } from 'react';
import { ConfirmDeletionModal } from 'src/components/Library/ConfirmDeletionModal';
import { useDisclosure } from '@mantine/hooks';
import { useDispatch } from 'react-redux';
import { widgetsActions } from 'src/components/Widget/store';
import { SelectedVideosListManager } from './SelectedVideosListManager/SelectedVideosListManager';
import {WidgetVideo} from "../../../types";

const useStyles = createStyles((theme) => ({
  noVideosText: {
    color: theme.colors.whitePrimary,
    textAlign: 'center',
    fontFamily: '"Segoe UI", sans-serif',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '110%',
  },
  noVideosTextContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
  },
  cardsContainer: {
    display: 'flex',
    width: '100%',
    flexGrow: 1,
    flexWrap: 'wrap',
    gap: '35px 26px',
    padding: '28px 30px',
    position: 'relative',
    height: '100%',
  },
}));

interface Props {
  emptyText: string;
  videos: WidgetVideo[];
  addedAutomatically?: boolean;
}
export const SelectedVideosList: FC<Props> = ({ emptyText, videos, addedAutomatically }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [confirmationModalState, setConfirmationModalState] = useDisclosure(false);

  const [selectedVideos, setSelectedVideos] = useState<string[]>([]);
  const [videoId, setVideoId] = useState<VideoResponseDeprecated['id'] | null>(null);

  const deleteVideo = (id: string) => {
    setVideoId(id);
    setConfirmationModalState.open();
  };

  const confirmDeleteVideoFromWidgetVideos = () => {
    if (!videoId) return;
    dispatch(widgetsActions.deleteVideosFromWidget([videoId]));
    setVideoId(null);
    setConfirmationModalState.close();
  };

  const checkVideoHandler = (id: string) => {
    if (selectedVideos.includes(id)) {
      setSelectedVideos((prev) => prev.filter((videoId) => videoId !== id));
    } else {
      setSelectedVideos((prev) => [...prev, id]);
    }
  }

  return (
    <>
      <Portal target="#tabs-portal">
        <Box className={classes.cardsContainer}>
          {videos.length < 1 && (
            <Box className={classes.noVideosTextContainer}>
              <Text className={classes.noVideosText}>{emptyText}</Text>
            </Box>
          )}
          {videos.length > 0 &&
            videos.map((vid) => (
              <VideoCard
                key={vid.id}
                id={vid.id}
                captionUrl={vid.preview ?? undefined}
                title={vid.title}
                state="Data"
                type={vid.type}
                checked={selectedVideos.includes(vid.id)}
                duration={vid.duration ?? '00:00'}
                gif={vid.gif}
                onChecked={checkVideoHandler}
                onDelete={!addedAutomatically ? deleteVideo : undefined}
              />
            ))}
        </Box>
        <ConfirmDeletionModal
          onClose={setConfirmationModalState.close}
          open={confirmationModalState}
          onCancel={setConfirmationModalState.close}
          onConfirm={confirmDeleteVideoFromWidgetVideos}
          bodyText={
            "Are you sure you want to remove 1 video from the widget? This action doesn't delete video from the library."
          }
          title="Delete video"
        />
      </Portal>

      {selectedVideos.length ?
        <SelectedVideosListManager
          selectedVideos={selectedVideos}
          onCancel={() => setSelectedVideos([])}
        />
        : null}
    </>
  );
};
