import {apiClient} from "../client";
import {AxiosRequestConfig, AxiosRequestHeaders} from "axios";

interface Config extends AxiosRequestConfig {
  params: {
    take: number
    skip: number
  }
}

export type GetWidgetProductResponse ={
  id: string;
  product_id: string;
  product_type: string | null;
  image: string | null;
  title: string;
  myshopify_domain: string | null;
  price?: number;
  collectionIds: string[];
}[]

export const getWidgetProducts = async (config: Config) => {
  return apiClient.get<GetWidgetProductResponse>(`/bff/widgets/platform/products`, config);
}
