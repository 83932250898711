import { Video } from '../VideosList/VideosList';

export enum WidgetViewType {
  carousel = 'carousel',
  stories = 'stories',
}

export enum WidgetStatus {
  draft = 'draft',
  published = 'published',
  notInstalled = 'notInstalled',
}

export enum PageType {
  collection = 'collection',
  product = 'product',
  home = 'home',
}

export enum LayoutSize {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}
export enum BehaviorAnimation {
  off = 'off',
  pulse = 'pulse',
  shine = 'shine',
}
export enum BehaviorAutoplay {
  off = 'off',
  enable = 'enable',
  hover = 'hover',
}
export enum BuyButtonType {
  add = 'add',
  virtual = 'virtual',
  direct = 'direct',
}
export enum BuyButtonIcon {
  cart = 'cart',
  bag = 'bag',
}
export enum BrandingType {
  chatpoint = 'chatpoint',
  custom = 'custom',
}
export enum HeadingFontWeight {
  light = 'light',
  regular = 'regular',
  bold = 'bold',
}
export enum HeadingAlignment {
  left = 'left',
  center = 'center',
  right = 'right',
}

export enum SetupTabs {
  videos = 'videos',
  settings = 'settings',
  targeting = 'targeting',
  installation = 'installation',
}

export interface WidgetSettings {
  general: {
    widgetViewType: WidgetViewType;
  };
  layout: {
    spacing: number;
    size: LayoutSize;
    borderRadius: number;
    background: string;
    showArrows: boolean;
    showDots: boolean;
    showBorder: boolean;
    borderThickness: number;
    borderColor: string;
    showHeading: boolean;
    headingText: string | undefined;
    headingTextSize: number;
    headingFontWeight: HeadingFontWeight;
    headingTextColor: string;
    headingAlignment: HeadingAlignment;
    headingBottomPadding: number;
  };
  behavior: {
    animation: BehaviorAnimation;
    autoplay: BehaviorAutoplay;
  };
  buyButton: {
    type: BuyButtonType;
    text: string | undefined;
    icon?: BuyButtonIcon;
    color: string;
    backgroundColor: string;
    borderRadius: number;
    showBorder: boolean;
    borderThickness: number;
    borderColor: string;
  };
  branding: {
    showLogo: boolean;
    type?: BrandingType;
    url?: string;
    fileName: string;
  };
}

export type WidgetVideo = {
  id: string;
  mux: string | null;
  preview: string | null;
  gif: string | null;
  type: 'Instagram' | 'Upload';
  title: string;
  duration: string | null;
}

export type Widget = {
  id: string;
  user_id: string;
  product_id?: string;
  myshopify_domain?: string;
  type: WidgetCreationType;
  settings: WidgetSettings;
  pages: string[];
  status: WidgetStatus;
  videos: WidgetVideo[];
  page: PageType;
  name: string;
  allPages: boolean;
};

export enum WidgetCreationType {
  manual = 'Manual',
  automated = 'Automated',
}

export type CreateAutomatedWidget = {
  status: WidgetStatus;
  page: PageType;
  settings: WidgetSettings;
  type: WidgetCreationType;
  name: string;
  allPages: boolean;
  pages?: string[];
};

export type WidgetProductDetails = {
  id: string;
  name: string;
  isVisible: boolean;
  order: number;
  value: string;
  metaInformation: string;
}


export type ActiveWidget = {
  id: string;
  user_id: string;
  type: WidgetCreationType;
  settings: WidgetSettings;
  pages: string[];
  page: PageType;
  status: WidgetStatus;
  videos: WidgetVideo[];
  name: string;
  allPages: boolean;
  productDetails?: WidgetProductDetails[];
};

export type CreateManualWidget = {
  status: WidgetStatus;
  pages: string[];
  settings: WidgetSettings;
  type: WidgetCreationType;
};

export type UpdateWidgetProductDetails = {
  widgetProductDetailId: string;
  isVisible: boolean;
  order: number;
}

export type UpdateWidget = {
  id: string;
  myshopify_domain?: string;
  status?: WidgetStatus;
  page?: PageType;
  settings?: WidgetSettings;
  type?: WidgetCreationType;
  user_id?: string;
  name?: string;
  pages?: string[];
  allPages?: boolean;
  videos?: string[];
  productDetails?: UpdateWidgetProductDetails[];
};

