import {useQuery} from "@tanstack/react-query";
import {queryKey} from "../../query/keys";
import {getWidgetProducts} from "../../../api/widget/getWidgetProducts";

export const useGetWidgetProducts = () => {
  return useQuery({
    queryKey: queryKey.widgetProducts,
    queryFn: () => getWidgetProducts({
      params: {
        take: 10,
        skip: 0
      }
    }).then(res => res.data)
  })
}
