import {Box, Center, Text} from '@mantine/core';
import {useState} from 'react';
import {SegmentSwitch} from 'src/components/SegmentSwitch/SegmentSwitch';
import {OptionAutomated} from './Automated/OptionAutomated';
import {OptionManual} from './Manual/OptionManual';
import {ReactComponent as IconManual} from 'src/shared/icons/settings-manual.svg';
import {VideoResponseDeprecated} from 'src/components/Library/LibraryHeader/types';
import {useDispatch, useSelector} from 'react-redux';
import {selectActiveWidget} from '../../../selectors';
import {widgetsActions} from 'src/components/Widget/store';
import {PageType, WidgetCreationType, WidgetVideo} from '../../../types';
import {IconWand} from '@tabler/icons-react';
import {sendWayToAddVideoSelectedEvent} from "../../../../../shared/eventLogger/eventLogger";
import {useGetWidgetVideos} from "../../../../../shared/hooks/tanstackQuery/useGetWidgetVideos";

const addingVideos = [
  {
    value: WidgetCreationType.automated,
    label: (
      <Center>
        <IconWand />
        <Box ml={5}>Automated</Box>
      </Center>
    ),
  },
  {
    value: WidgetCreationType.manual,
    label: (
      <Center>
        <IconManual />
        <Box ml={5}>Manual</Box>
      </Center>
    ),
  },
];
const showProductVideos = (videos: VideoResponseDeprecated[], currentProd: string | null) => {
  if (!currentProd) return [];
  return videos.filter((vid) =>
    vid.products.length < 1 ? false : vid.products.some((prod) => prod.productId === currentProd)
  );
};
export const Videos = () => {
  const dispatch = useDispatch();

  const activeWidget = useSelector(selectActiveWidget);
  const manaulVideos = activeWidget?.videos || [];
  const option = activeWidget?.type || WidgetCreationType.automated;
  const [pageId, setPageId] = useState<string>()
  const {data: automatedVideos} = useGetWidgetVideos({
    collectionId: activeWidget?.page === PageType.collection ? pageId : undefined,
    productId: activeWidget?.page === PageType.product ? pageId : undefined,
    widgetId: activeWidget?.id || '',
  })
  const videos: WidgetVideo[] = option === WidgetCreationType.automated ? automatedVideos || [] : manaulVideos;
  const changeTargetPage = (pageId: string) => {
    setPageId(pageId);
  }

  const onChangeWidgetType = (v: WidgetCreationType) => {
    sendWayToAddVideoSelectedEvent({wayToAdd: v})
    dispatch(widgetsActions.setWidgetType(v))
  }

  return (
    <Box>
      <Box>
        {activeWidget?.page !== 'home' && (
          <>
            <Text mb={12} color="#c1c2c5">
              Select the way to add videos to your widget:
            </Text>
            <SegmentSwitch
              data={addingVideos}
              onChange={onChangeWidgetType}
              value={option}
              variant="secondary"
              size="lg"
            />
          </>
        )}
        {option === 'Automated' && activeWidget && activeWidget.page !== 'home' && (
          <OptionAutomated
            videos={videos}
            changePage={(val: string) => changeTargetPage(val)}
            currentPage={pageId }
            pageType={activeWidget.page}
          />
        )}
        {option === 'Manual'  && activeWidget && <OptionManual videos={videos} widgetId={activeWidget.id} />}
      </Box>
    </Box>
  );
};
