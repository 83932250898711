import { Carousel } from '@mantine/carousel';
import {Box, Button, createStyles, Flex, Image, Progress} from '@mantine/core';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectWidgetSettings } from 'src/components/Widget/selectors';
import shopCardCarouselImage from 'src/shared/images/temporary/shop-card.png';
import { iconsList } from '../../BuyButton/BuyButtonSettings';
import { widgetSettingsInit } from 'src/components/Widget/store';
import {MobileView} from "../Layout/MobileView";
import {ViewManager} from "../Layout/Layout.components";
import {useGetWidgetProducts} from "../../../../../../../shared/hooks/tanstackQuery/useGetWidgetProducts";

const useStyles = createStyles((theme) => ({
  rootView: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: 'max-content',
    width: '100%',
  },
  root: {
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    paddingTop: '20px',
    backgroundColor: theme.colors.whitePrimary,
    position: 'relative',
  },
  progressRoot: {
    width: '100%',
    backgroundColor: '#E9ECEF',
  },
  progressBar: {
    transitionDuration: '0ms',
  },
  productTitle: {
    color: theme.colors.blackPrimary,
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%',
    margin: 0,
  },
  productPrice: {
    color: theme.colors.blackPrimary,
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '150%',
  },
  productInfo: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'flex-start',
  },
  quantitySection__title: {
    color: '#838383',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%',
  },
  quantitySection__btn: {
    border: `1px solid ${theme.colors.greyLight[4]}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 18px',
    marginBottom: '20px',
    span: {
      display: 'inline-block',
      color: theme.colors.blackPrimary,
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '150%',
    },
  },
  addToCartButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '7px 0',
    height: '48px',
    span: {
      marginLeft: '10px',
    },
  },
}));

export const ShoppingCardPreview = () => {
  const { classes } = useStyles();

  const { buyButton } = useSelector(selectWidgetSettings) ?? widgetSettingsInit;
  const {data: products} = useGetWidgetProducts()
  // const products = useSelector(selectProducts);
  const buttonsStyles = useMemo(
    () => ({
      backgroundColor: buyButton.backgroundColor,
      color: buyButton.color,
      borderRadius: buyButton.borderRadius,
      border: buyButton.showBorder
        ? `${buyButton.borderThickness}px solid ${buyButton.borderColor}`
        : 'none',
      svg: {
        stroke: buyButton.color,
        path: {
          stroke: buyButton.color,
        },
      },
    }),
    [buyButton]
  );
  const productsArray = products || []
  const [prodIdx, setProdIdx] = useState<number>(0);

  const [viewport, setViewport] = useState<HTMLDivElement | null>(null)

  return (
    <Box className={classes.rootView}>
      <ViewManager
        mobile={
          <Flex sx={{ flexGrow: 1 }} justify="center">
          <MobileView getViewport={setViewport} isShowSkeleton={false}>
          <Box className={classes.root}>
            {productsArray.length < 1 ? (
              <Image src={shopCardCarouselImage} px={10} height={200} />
            ) : (
              <Carousel
                height={200}
                slideSize={viewport?.clientWidth ?? 326}
                withControls
                loop
                onSlideChange={setProdIdx}
              >
                {productsArray.map(({ image, id }) => (
                  <Carousel.Slide key={id}>
                    <Image src={image} key={id} />
                  </Carousel.Slide>
                ))}
              </Carousel>
            )}
            <Progress
              classNames={{
                bar: classes.progressBar,
                root: classes.progressRoot,
              }}
              size="sm"
              mt="xl"
              mx="auto"
              mb={58}
            />
            <Box px={20} w={'100%'} sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
            }} >
              <Box className={classes.productInfo}>
                <Box component="h3" className={classes.productTitle}>
                  {(productsArray.length > 0 && productsArray[prodIdx].title) ?? 'Product Title'}
                </Box>
                <Box component="span" className={classes.productPrice}>
                  {(productsArray.length > 0 && productsArray[prodIdx]?.price) ?? 100}$
                </Box>
              </Box>
              <Box>
                <span className={classes.quantitySection__title}>Quantity</span>
                <Box className={classes.quantitySection__btn}>
                  <span>-</span>
                  <span>1</span>
                  <span>+</span>
                </Box>
                <Button className={classes.addToCartButton} style={buttonsStyles}>
                  {iconsList.filter((i) => i.value === buyButton.icon)[0].icon({ size: 18 })}
                  <span>{buyButton.text}</span>
                </Button>
              </Box>
            </Box>
          </Box>
        </MobileView>
          </Flex>
        }

    />
    </Box>
  );
};
