import {useState} from "react";
import {notifications} from "@mantine/notifications";
import {getVideoFileDuration} from "./helpers/getVideoFileDuration";
import {maxVideoDurationInSeconds} from "./consts";
import axios from "axios";
import {postCreateVideoFromFile} from "../api/video/postCreateVideoFromFile";
import {invalidateQueryVideos} from "./query/invalidateQueryVideos";
import {QueryVideoFilter} from "../api/video/getVideos";
import {UpChunk} from "@mux/upchunk";
import {postVideoWasUploaded} from "../api/video/postVideoWasUploaded";
import {queryClient} from "../api/client";
import {queryKey} from "./query/keys";

export const useVideoFilesUploader = () => {
  const [videos, setVideos] = useState<File[]>([]);
  const upload = async () => {
    const videUploadingIds: string[] = []
    notifications.show({
      id: 'video_files_uploading',
      message: 'Please keep this tab open until the files finish uploading',
      loading: true,
      autoClose: false,
    })

    const filteredVideos: File[] = []
    for (const video of videos) {
      const videoDuration = await getVideoFileDuration(video)
      if (videoDuration < maxVideoDurationInSeconds) {
        filteredVideos.push(video)
      }
    }

    const createdVideos = await axios.all(filteredVideos.map(async (videoFile) => {
      const videoData = await postCreateVideoFromFile({
        videoName: videoFile.name,
        lastModified: videoFile.lastModified,
      }).then((response) => response.data)
      return {
        videoData,
        videoFile,
      }
    }))
    const videoIds = createdVideos.map((video) => video.videoData.videoId)
    videUploadingIds.push(...videoIds)

    invalidateQueryVideos({
      filters: Object.values(QueryVideoFilter)
    })
    queryClient.invalidateQueries({
      queryKey: queryKey.videosCountPerFilter
    })

    for (let i = 0; i < createdVideos.length; i++) {
      const videoFile = createdVideos[i].videoFile
      const createdVideo = createdVideos[i].videoData

      const upload = new UpChunk({
        endpoint: createdVideo.uploadUrl,
        file: videoFile,
        chunkSize: 5120,
      })
      upload.on('progress', (progress: {
        detail: number
      }) => {
      })
      upload.on('success', (data) => {

        postVideoWasUploaded({
          videoId: createdVideo.videoId,
          uploadId: createdVideo.uploadId,
          uploadUrl: createdVideo.uploadUrl,
        })
        videUploadingIds.splice(videUploadingIds.indexOf(createdVideo.videoId), 1)
        const isAllVideosUploaded = videUploadingIds.length === 0
        if (isAllVideosUploaded) {
          notifications.hide('video_files_uploading')
          notifications.show({
            message: 'Your videos have been successfully uploaded',
            autoClose: 5000,
          })
        }
      })
      setVideos([])
    }
  }

  return {
    videos,
    setVideos,
    upload,
  }
}
