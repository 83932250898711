export interface MockTariffPlanInfo {
  planId?: string
  description?: { text: string }[]
  visitors?: number
  additionalVisitorsPrice?: number
}

export const mockTariffPlans: MockTariffPlanInfo[] = [{
  // free
  planId: '70889e7b-7a85-43b5-98de-b25449d24cdf',
  visitors: 1000,
  description: [
    {text: "ALL FEATURES"},
    {text: "2 Widgets"},
    {text: "Unlimited Widget Views"},
    {text: "Auto-import from Instagram"},
    {text: "Auto-add Videos into Widgets"},
    {text: "Upload from Local Storage"},
    {text: "Auto-add videos into widgets"},
    {text: "Basic Analytics"},
    {text: "VideoPoint Branding"},
    {text: "Chat Support"},
  ],
}, {
  // basic
  planId: 'd049c1e0-8040-4b1b-b7de-0b8df9deebe3',
  visitors: 6000,
  additionalVisitorsPrice: 5,
  description: [
    {text: "ALL FEATURES"},
    {text: "4 Widgets"},
    {text: "Unlimited Widget Views"},
    {text: "Auto-import from Instagram"},
    {text: "Auto-add Videos into Widgets"},
    {text: "Upload from Local Storage"},
    {text: "Auto-add videos into widgets"},
    {text: "Detailed Analytics"},
    {text: "Removable Branding"},
    {text: "Chat Support"},
  ]
}, {
  // standard
  planId: 'de06c4d7-9c6e-471f-9c95-fc75a92801f2',
  visitors: 30000,
  additionalVisitorsPrice: 4,
  description: [
    {text: "ALL FEATURES"},
    {text: "Unlimited Widgets"},
    {text: "Unlimited Widget Views"},
    {text: "Auto-import from Instagram"},
    {text: "Upload from Local Storage"},
    {text: "Auto-add Videos into Widgets"},
    {text: "Detailed Analytics"},
    {text: "Removable Branding"},
    {text: "Assisted implementation"},
    {text: "Feature requests"},
    {text: "Priority Support"},
  ]
}, {
  // growth
  planId: 'cc201ec8-5f1f-4934-b209-07ad277b23cc',
  visitors: 180_000,
  additionalVisitorsPrice: 3,
  description: [
    {text: "ALL + Beta FEATURES"},
    {text: "Unlimited Widgets"},
    {text: "Unlimited Widget Views"},
    {text: "Auto-import from Instagram"},
    {text: "Upload from Local Storage"},
    {text: "Auto-add Videos into Widgets"},
    {text: "Detailed Analytics"},
    {text: "Removable Branding"},
    {text: "Assisted implementation"},
    {text: "Feature requests"},
    {text: "Priority Support"},
    {text: "Monthly Strategy Calls"},
    {text: "Testing Backlog"},
    {text: "Roadmap Priority"},
    {text: "Slack Channel"},
  ]
}]
