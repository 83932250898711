import React from 'react';
import {ActionIcon, Box, createStyles, TextInput} from "@mantine/core";
import {IconX} from "@tabler/icons-react";
import {ReactComponent as SelectIcon} from 'src/shared/icons/select-icon.svg';

const useStyles = createStyles((theme) => ({
  dropdown__scrollableArea: {
    padding: '5px',
    boxSizing: 'border-box',
  },
  notFound: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '67px',
    padding: '4px 10px',
    color: theme.colors.greyLight[2],
  },
  inputRightSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '72px',
  },
  input: {
    paddingRight: '72px',
  },

  select__iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    marginRight: '14px',
  },
  select: {
    position: 'relative',
  },
  dropdown: {
    position: 'absolute',
    top: '44px',
    borderRadius: '4px',
    border: `1px solid ${theme.colors.greyDark[1]}`,
    backgroundColor: theme.colors.greyDark[5],
    zIndex: 300,
    width: '100%',
  },
}));

interface SearchProps {
  search?: string;
  searchHandler?: (value: string) => void;
  onFocus: () => void;
  isAutoFocus?: boolean;
  placeholder?: string;
}

export const Search: React.FC<SearchProps> = ({
  searchHandler,
  search = '',
  onFocus,
  isAutoFocus,
  placeholder,
                                              }) => {
  const {classes} = useStyles();

  return (
    <TextInput
      data-autofocus={isAutoFocus}
      w="100%"
      placeholder={placeholder}
      value={search}
      onChange={(e) => searchHandler?.(e.target.value)}
      onFocus={onFocus}
      data-select
      classNames={{
        input: classes.input,
        rightSection: classes.inputRightSection,
      }}
      rightSection={
        <>
          {search && (
            <ActionIcon variant="transparent" onClick={() => searchHandler?.('')} data-select>
              <IconX size={14} color="#868E96" style={{pointerEvents: 'auto'}}/>
            </ActionIcon>
          )}
          <Box className={classes.select__iconWrapper}>
            <SelectIcon/>
          </Box>
        </>
      }
      rightSectionProps={{
        style: {
          pointerEvents: 'none',
        }
      }}
    />
  );
};

