import {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import { videoListActions } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import {selectCheckedVideos, selectDeletedVideoIds} from '../selectors';
import { VideoResponseDeprecated } from '../LibraryHeader/types';
import styles from './LibraryVideoList.module.scss';
import { useDisclosure } from '@mantine/hooks';
import { Product } from '../../Products/types';
import { AddProductsModal } from '../../Products/AddProductsModal';
import { VideoCard } from '../../VideoCard/VideoCard';
import { ConfirmDeletionModal } from '../ConfirmDeletionModal';
import {Box, Skeleton} from '@mantine/core';
import {MainVideoFilter, VideoFilter} from '../LibraryLayout/LibraryLayout';
import {useVideos} from "../../../shared/hooks/useVideos";
import {videoFilterToVideoQueryFilter} from "../../../shared/helpers/videoFilterToVideoQueryFilter";
import {FetchVideosResponse} from "../../../api/video/getVideos";
import {useVirtualizer} from "@tanstack/react-virtual";
import useResizeObserver from "use-resize-observer";
import {useLibraryDeleteVideos} from "../../../shared/hooks/useLibraryDeleteVideos";
import {useTagProductsToVideos} from "../../../shared/hooks/useTagProductsToVideos";

export interface LibraryVideoListProps {
  gapWidth?: number;
  gapHeight?: number;
  currentFilter?: VideoFilter;
  getInnerModalState?: (state: boolean) => void;
  addVideoToWidget?: (id: VideoResponseDeprecated['id']) => void;
  selectedVideos?: string[];
  parentRef: React.RefObject<HTMLDivElement>;
  fetchNextPage: ()=> void;
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  videos: FetchVideosResponse[0][];
}

export const LibraryVideoList: FC<LibraryVideoListProps> = ({
  gapWidth = 46,
  gapHeight = 46,
  currentFilter = { type: MainVideoFilter.Inbox },
  getInnerModalState,
  addVideoToWidget,
  selectedVideos,
  parentRef,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
  videos
}) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [video, setActiveVideo] = useState<FetchVideosResponse[0] | null>(null);
  const dispatch = useDispatch();






  const checkedVideos = useSelector(selectCheckedVideos);
  const [confirmationModalState, setConfirmationModalState] = useDisclosure(false);
  const [videoId, setVideoId] = useState<VideoResponseDeprecated['id'] | null>(null);

  const videosRef= useRef(videos);

  useEffect(() => {
    videosRef.current = videos;
  }, [videos]);

  const onAddProducts = useCallback((id: FetchVideosResponse[0]['id']) => {
    const video = videosRef.current.find((video) => video.id === id) || null
    setActiveVideo(video);
    open();
  },[]);

  const {mutate: tagProductsToVideo} = useTagProductsToVideos()

  const onSave = useCallback((products: {
    toDelete: string[];
    toAdd: string[]
  }) => {
    if (!video) {
      return;
    }
    tagProductsToVideo({
      toAdd: products.toAdd,
      toDelete: products.toDelete,
      id: video.id,
    })
    close();
  },[video, dispatch, close]);

  const onChecked = useCallback((id: VideoResponseDeprecated['id']) => {
    dispatch(videoListActions.checkVideo(id));
  },[]);
  const onDelete = useCallback((id: VideoResponseDeprecated['id']) => {
    setVideoId(id);
    setConfirmationModalState.open();
  },[]);

  const onSuccessVideoDeletion = useCallback(() => {
    setVideoId(null);
    setConfirmationModalState.close();
  },[])

  const {mutate: deleteVideos} = useLibraryDeleteVideos({
    currentFilter,
    onSuccess: onSuccessVideoDeletion
  })


  const onDeletionConfirm = useCallback(() => {
    if (!videoId) return;
    deleteVideos({
      ids: [videoId],
    })

  }, [videoId]);

  const onCancel = useCallback( () => {
    setVideoId(null);
    setConfirmationModalState.close();
  },[])


  useEffect(() => {
    if (getInnerModalState) {
      getInnerModalState(!opened);
    }
  }, [getInnerModalState, opened]);

  const [itemsPerRow, setItemsPerRow] = useState<number | undefined>();

  const updateItemsPerRow = useCallback(({width}: {width: number}) => {
      const minWidth = 180;
      const maxItems = Math.floor(width / (minWidth + gapWidth / 2));
      setItemsPerRow(maxItems);
      const totalGaps= (maxItems - 1) * gapWidth;

    const cardWidth= (width - totalGaps) / maxItems
    document.documentElement.style.setProperty("--card-width", `${cardWidth}px`);
  }, []);

  const conteinerRef = useRef<HTMLDivElement>(null);
  useResizeObserver({
    ref: conteinerRef,
    onResize: ({width})=>{
      if(width) {
        updateItemsPerRow({width})
      }
    },
  });

  const rowCount = itemsPerRow ?  Math.ceil(videos.length   / itemsPerRow) : 0;
  const rowVirtualizer = useVirtualizer({
    count: rowCount,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 240,
    overscan: 5,
    gap: gapHeight,
  });

  useEffect(() => {
    const [lastItem] = [...rowVirtualizer.getVirtualItems()].reverse()

    if (!lastItem) {
      return
    }

    const isNeedToFetch =   lastItem.index >= rowCount - 1 &&
      hasNextPage &&
      !isFetchingNextPage
    if (
      isNeedToFetch
    ) {
      fetchNextPage()
    }
  }, [
    hasNextPage,
    fetchNextPage,
    rowCount,
    isFetchingNextPage,
    rowVirtualizer.getVirtualItems(),
  ])

  const {defaultRowItems, lastRowItems} = useMemo(()=>{
    const defaultRowItems = Array.from({ length: itemsPerRow ?? 0 }, (_, index) => {
      return `item-${Math.random()}`
    });
    let itemsLastRow = itemsPerRow ?  videos.length % itemsPerRow : undefined;
    if(itemsLastRow === 0) {
      itemsLastRow = itemsPerRow;
    }
    const lastRowItems =  Array.from({ length: itemsLastRow ?? 0 }, (_, index) => {
      return `item-${Math.random()}`
    })

    return {
      defaultRowItems,
      lastRowItems
    }
  },[itemsPerRow, videos.length])

  return (
    <Box ref={conteinerRef} >
      <Box
        className={styles.container} >
        {video && (
          <AddProductsModal opened={opened} onClose={close} video={video} onSave={onSave} />
        )}
        {itemsPerRow && <Box
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
            width: '100%',
            position: 'relative',
          }}
        >
          {rowVirtualizer.getVirtualItems().map((virtualRow) => {
            const startIndex = virtualRow.index * itemsPerRow;
            const isLastRow = rowCount - 1 === virtualRow.index;
            const rowItems = isLastRow ? lastRowItems : defaultRowItems;
            return (
              <div
                id={`row-${virtualRow.index}`}
                key={virtualRow.index}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: `${virtualRow.size}px`,
                  transform: `translateY(${virtualRow.start}px)`,
                  display: 'flex',
                  gap: gapWidth,
                }}
              >
                {rowItems.map((rowItem, index) => {
                  const video = videos[startIndex + index];
                  return (
                    <div className={styles.row__item} key={rowItem}>
                      {video ? <VideoCard
                        state={video.status}
                        id={video.id}
                        title={video.title}
                        type={video.type}
                        captionUrl={video.preview}
                        checked={(selectedVideos ?? checkedVideos).includes(video.id)}
                        onChecked={addVideoToWidget ?? onChecked}
                        onDelete={onDelete}

                        onAddProducts={onAddProducts}
                        isShoppable={video.isShoppable}
                        duration={video.duration ?? '00:00'}
                        gif={video.gif}
                      />: <Skeleton />}
                    </div>
                  )
                })}
              </div>
            );
          })}
        </Box>}
      </Box>
      <ConfirmDeletionModal
        onClose={setConfirmationModalState.close}
        open={confirmationModalState}
        onCancel={onCancel}
        onConfirm={onDeletionConfirm}
        bodyText={'Are you sure you want to delete 1 video from the Library?'}
        title="Delete video"
      />
    </Box>
  );
};
