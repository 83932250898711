import {queryClient} from "../../../api/client";
import {queryKey} from "../../query/keys";
import {GetWidgetsResponse} from "../../../api/widget/getWidgets";


export const useGetWidgetsManger = () => {
  const refetch = () => {
    queryClient.invalidateQueries({
      queryKey: queryKey.widgets,
    });
  }



  const editGetWidgets = (widgets: (Pick<GetWidgetsResponse[0], 'id'> & Partial<GetWidgetsResponse[0]>)[]) => {
    queryClient.setQueryData<{pages: GetWidgetsResponse[]}>(queryKey.widgets, (oldData) => {
      if(!oldData) return;
      const updatedPages = oldData.pages.map((page) => {
        const updatedPage = page.map(oldWidget => {
          const updatedWidget = widgets.find((w) => w.id === oldWidget.id);
          if (updatedWidget) {
            return {
              ...oldWidget,
              ...updatedWidget,
            }
          }
          return oldWidget
        })
        return updatedPage
      });
      return {
        ...oldData,
        pages: updatedPages,
      }
    });
  }
  return {
    refetch,
    editGetWidgets,
  }
}
