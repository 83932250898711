import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from './selectors';
import {LoginScreen} from "../LoginScreen/LoginScreen";
import styles from './User.module.scss';
import { patchMe } from '../../api/me/patchMe';
import { FacebookLoginButton } from '../LoginScreen/LoginButton/FacebookLoginButton';
import { userActions } from './store';
import { FacebookLoginClient } from '@greatsumini/react-facebook-login';
import {useVideosCountForAllFilters} from "../../shared/hooks/useVideosCountForAllFilters";
import {useGetUser} from "../../shared/hooks/tanstackQuery/useGetUser";
import {usePatchUser} from "../../shared/hooks/tanstackQuery/usePatchUser";

export const User = () => {
  const { data: user } = useGetUser();
  const {mutate: patchUser} = usePatchUser({
    onSuccess: () => {
        FacebookLoginClient.logout(console.log)
    }
  })
  const {data: videoCount} = useVideosCountForAllFilters();
  const isAnyVideo = videoCount ? Object.values(videoCount).map((count) => count > 0).some(Boolean) : false;

  const dispatch = useDispatch();
  const isConnectingInstagram = useSelector(selectUser).isWaitingVideosFromInstagram;
  if (!user?.instagram_token || isConnectingInstagram) {
    return <LoginScreen />;
  }

  const Title = () => {
    return isAnyVideo ? (
      <h1 className={styles.title}>You're logged in to Facebook</h1>
    ) : (
      <h1 className={styles.title}>
        There are no videos in your Instagram account,
        <br />
        please add videos, log out with the button below,
        <br />
        and then continue with Facebook again
      </h1>
    );
  };
  return (
    <>
      <div className={styles.container}>
        <Title />
        <div>
          <div
            onClick={() =>
              patchUser({
                instagramToken: '',
              })
            }
          >
            <FacebookLoginButton isConnected />
          </div>
        </div>
      </div>
    </>
  );
};
