import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { ReactNode, FC } from 'react';
import {
  numberInputOverride,
  radioOverride,
  sliderOverride,
  switchOverride,
  segmentedControlOverride,
  selectOverride,
  scrollAreaOverride,
  skeletonOverride,
  buttonOverride,
} from './componentsOverrides';
import { ModalsProvider } from '@mantine/modals';
import {loaderOverride} from "./componentsOverrides/loaderOverride";
import {navbarOverride} from "./componentsOverrides/navbar.override";


interface ThemeProviderProps {
  children: ReactNode;
}
export const ThemeProvider: FC<ThemeProviderProps> = ({ children }) => {
  return (
    <MantineProvider
      theme={{
        colorScheme: 'dark',
        focusRingStyles: {
          resetStyles: () => ({ outline: 'none' }),
          styles: () => ({ outline: 'none' }),
          inputStyles: (theme) => ({ borderColor: theme.colors.green[0], outline: 'none' }),
        },
        colors: {
          whitePrimary: ['#fff'],
          blackPrimary: ['#000'],
          black: ['#1A1B1E', '#141517'],
          greyLight: ['#DEE2E6', '#D9D9D9', '#C1C2C5', '#A6A7AB', '#979797', '#909296', '#868E96'],
          greyDark: ['#5C5F66', '#373A40', '#343A40', '#383A3F', '#2C2E33', '#25262B'],
          green: ['#17E885', '#00AD60', '#153F2D'],
          red: ['#E03131', '#3E2324'],
          pink: ['#F7CBCA'],
        },
        components: {
          ...numberInputOverride,
          ...sliderOverride,
          ...radioOverride,
          ...segmentedControlOverride,
          ...switchOverride,
          ...selectOverride,
          ...scrollAreaOverride,
          ...skeletonOverride,
          ...buttonOverride,
          ...loaderOverride,
          ...navbarOverride,
        },
      }}
    >
      <Notifications position="top-center" zIndex={2077} limit={5} />
      <ModalsProvider>{children}</ModalsProvider>
    </MantineProvider>
  );
};
