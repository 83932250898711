import {useDispatch, useSelector} from "react-redux";
import {selectUser} from "../User/selectors";
import {Box, Loader, Tooltip} from "@mantine/core";
import {SuccessResponse} from "@greatsumini/react-facebook-login/dist/types/response.type";
import {userActions} from "../User/store";
import styles from "./LoginScreen.module.scss";
import {patchMe} from "../../api/me/patchMe";
import FacebookLogin, {FacebookLoginClient} from "@greatsumini/react-facebook-login";
import {FacebookLoginButton} from "./LoginButton/FacebookLoginButton";
import React, {useEffect, useState} from "react";
import {useOnSuccessMetaConnected} from "../../shared/hooks/useOnSuccessMetaConnected";
import {LoginButtonWrapper} from "./LoginButton/LoginButtonWrapper/LoginButtonWrapper";
import {ReactComponent as IconInstagram} from 'src/shared/icons/instagram.svg';
import {ReactComponent as IconUpload} from 'src/shared/icons/upload.svg';
import {useOpen} from "../../shared/hooks/useOpen";
import {VideoFileUploaderModal} from "../VideoFileUploader/VideoFileUploaderModal/VideoFileUploaderModal";
import {LoginScreenForFBReview} from "./LoginScreenForFBReview/LoginScreenForFBReview";
import {useGetUser} from "../../shared/hooks/tanstackQuery/useGetUser";
import {usePatchUser} from "../../shared/hooks/tanstackQuery/usePatchUser";

interface LoginScreenProps {
  hasToken?: boolean;
}

export const LoginScreen: React.FC<LoginScreenProps> = ({hasToken}) => {
  const {data: user} = useGetUser()
  const { isFBReview } = useSelector(selectUser);
  const dispatch = useDispatch();

  const {onSuccess, isConnecting, scope} = useOnSuccessMetaConnected();

  const {mutate: patchUser} = usePatchUser({
    onSuccess: () => {
      if(hasToken) {
        FacebookLoginClient.logout(console.log)
      }
    }
  })

  const  uploadFilePopupManager = useOpen()
  const onSuccessFacebookHandler = (response: SuccessResponse) => {
    onSuccess(response);
  }

  const [loaderTooltipText, setLoaderTooltipText] = useState('Connecting to Instagram');

  useEffect(() => {
    if (isConnecting) {
      const firstTimeout = setTimeout(() => {
        setLoaderTooltipText('Fetching videos');
      }, 2000);

      const secondTimeout = setTimeout(() => {
        setLoaderTooltipText('Importing videos');
      }, 4000);

      return () => {
        clearTimeout(firstTimeout);
        clearTimeout(secondTimeout);
      };
    }
  }, [isConnecting]);

  if (isFBReview) {
    return  <LoginScreenForFBReview/>
  }

  if (isConnecting) {
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Tooltip sx={{
          color: '#17E885',
          backgroundColor: '#17E88533'
        }} opened position="top" label={loaderTooltipText}>
          <Box>
            <Loader size={'xl'}/>
          </Box>
        </Tooltip>
      </Box>
    )
  }

  return (
    <div className={styles.container}>

      <VideoFileUploaderModal isOpen={uploadFilePopupManager.isOpen} close={uploadFilePopupManager.close}/>

        <>

            {hasToken ? (
              <div
                onClick={() =>
                  patchUser({instagramToken: ''})
                }
              >
                <FacebookLoginButton isConnected />
              </div>
            ) : (
              <div className={styles.loginContainer}>
                <h1 className={styles.title}>
                  Connect sources to start
                  <br/>
                  creating widgets
                </h1>
              <div className={styles.buttonsContainer}>
                <FacebookLogin
                  appId="2769783553336873"
                  initParams={{
                    version: 'v16.0',
                  }}
                  onSuccess={onSuccessFacebookHandler}

                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  onFail={onSuccessFacebookHandler}
                  scope={scope}
                  className={styles.reset}
                  loginOptions={{
                    auth_type: 'rerequest',
                  }}
                >
                  {user && <LoginButtonWrapper Icon={IconInstagram} text="Instagram"
                                       buttonText={user.instagram_token ? 'Disconnect' : 'Connect'}/>
                  }                </FacebookLogin>
                {/*<LoginButtonWrapper Icon={IconTikTok} text="TikTok" buttonText="Connect" isAvailable={false} />*/}
                <LoginButtonWrapper Icon={IconUpload} text="Upload" buttonText="Upload" onClick={uploadFilePopupManager.open} />
              </div>
              </div>

            )}
        </>
    </div>
  );
};
